<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modalInput" class="w-100" title="Orden de Pedido">


      <b-row>
        <b-col sm="12" md="6">
      
        </b-col>

        <b-col sm="12" md="2">
          <b-form-group label="Desde :">
            <b-form-input @change="ListPurchaseOrder" class="text-center" :max="to" type="date"  ref="to" v-model="from"></b-form-input>
            <small v-if="errors.from" class="form-text text-danger" >Selccione una fecha</small>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="2">
          <b-form-group label="Hasta :">
            <b-form-input @change="ListPurchaseOrder" class="text-center" :min="from" type="date"  ref="from" v-model="to"></b-form-input>
            <small v-if="errors.to" class="form-text text-danger" >Selccione una fecha</small>
          </b-form-group>
        </b-col>
        
        <b-col sm="6" md="2">
          <b-form-group label=".">
            <b-input-group>
            <b-form-input v-model="search" class="form-control"></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="ListPurchaseOrder"><b-icon icon="search"></b-icon></b-button>
            </b-input-group-append>
          </b-input-group>
          </b-form-group>
        </b-col>

      </b-row>

      <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="15%" class="text-center">N° Pedido</th>
                    <th width="50%" class="text-center">Almacen</th>
                    <th width="10%" class="text-center">Usuario</th>
                    <th width="10%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ item.number }}</td>
                    <td class="text-left"> {{ item.warehouse_name }}</td>
                    <td class="text-left"> {{ item.user }}</td>
                    <td class="text-center">
                      <b-button type="button" @click="AddLinkages(item.id_purchase_order_user)" variant="primary"><i class="fas fa-plus-square"></i></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage"  v-on:input="ListPurchaseOrder"  :total-rows="rows"  :per-page="perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
    </b-modal>
  </div>
</template>
<style>


</style>



<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";

import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");

export default {
  name: "ModalsProduct",
  components:{
      vSelect,
  },
  data() {
    return {
        modalInput:false,
        module: 'Shopping',
        role: 0,
        perPage: 15,
        currentPage: 1,
        rows: 0,
        search: "",
        data_table: [],

        id_provider:0,
        to:moment(new Date()).local().format("YYYY-MM-DD"),
        from:moment().subtract(30, 'days').local().format("YYYY-MM-DD"),
        search: "",
        providers: [],
        provider:null,
        errors:{
          to:false,
          from:false,
        }
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalPurchaseOrderUserShow', (role) => {
      this.modalInput = true;
      this.role = role;
      this.ListPurchaseOrder();
    });
    
  },
  methods: {
      SearchProvider,
      CodeInvoice,
      ListPurchaseOrder,
      AddLinkages,
      
      ...mapActions('ShoppingOrder',['mLoadAddLinkages']),
      ...mapActions('ShoppingOrder',['mLoadAddShoppingDetail']),
      ...mapActions('ShoppingOrder',['mLoadTotalsShoppingDetail']),
      
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};

function SearchProvider(search, loading) {
  
    let me = this;
    let url = this.url_base + "search-providers/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.providers = response.data.result;
            loading(false);
      })
    }
    
}

function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}

function ListPurchaseOrder() {
  
  this.errors.from = false;
  this.errors.to = false;

  if (this.from.length == 0) {this.errors.from = true; return false;}
  if (this.to.length == 0) {this.errors.to = true; return false;}
  let search = this.search == "" ? "all" : this.search;

  let me = this;
  let url = this.url_base + "purchase-order-user/list-pending/"+ this.from + "/" + this.to + "/" + search + "?page=" + this.currentPage;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function AddLinkages(id_purchase_order_user) {
  
  let me = this;
  let url = this.url_base + "purchase-order-user/view/"+id_purchase_order_user;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role:this.role,},
  })
    .then(function (response) {
      
      if (response.data.status == 200) {
        
        let linkage = {
          module: 'PurchaseOrderUser',
          id_module: response.data.result.purchase_order_user.id_purchase_order_user,
          reference: response.data.result.purchase_order_user.number,
          broadcast_date : response.data.result.purchase_order_user.broadcast_date, 
        }
        me.mLoadAddLinkages(linkage);
        let input = {
          id_warehouse: response.data.result.purchase_order_user.id_warehouse
        }
        EventBus.$emit('CompletePurchase',input);
        let input_detail = response.data.result.purchase_order_user_detail;
        for (let index = 0; index < input_detail.length; index++) {
          const element = input_detail[index];
          let detail = {
            id_product : element.id_product,
            code : element.code,
            name : element.name,
            presentation : element.presentation,
            unit_measure : element.name_unit_measure,
            igv : element.igv,
            quantity : parseFloat(element.quantity).toFixed(2),
            percentage_discount: (0).toFixed(2),

            unit_value: (0).toFixed(5),
            unit_discount: (0).toFixed(5),
            net_unit_value: (0).toFixed(5),
            unit_igv: (0).toFixed(5),
            unit_price: (0).toFixed(5),

            total_value: (0).toFixed(5),
            total_discount: (0).toFixed(5),
            net_total_value: (0).toFixed(5),
            total_igv: (0).toFixed(5),
            total_price: (0).toFixed(5),
          }
          me.mLoadAddShoppingDetail(detail);
        }
        me.modalInput = false;
      } else {
        
      }
    })

    
}


</script>
